import React, { useState, useEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useParams
} from "react-router-dom"
import jwt from 'jwt-decode'
import axios from 'axios';

import { Layout, Menu, Card, Row, Button, Col, Space, Table, Modal, Form, Input, DatePicker, notification, Typography, Image } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import './App.css'
import image from './grano_logo.svg';
import Login from './login';

const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Text, Paragraph } = Typography;
function Admin() {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [apiToken, setapiToken] = useState("")
  const [formDisabled, setFormDisabled] = useState(false)
  const [form] = Form.useForm();
  useEffect(() => {

    const getData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwt(token)
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          console.log('error')
          localStorage.clear()
          navigate('/admin/login', { replace: true })
        } else {
          setapiToken(token)
          console.log('ok')
        }
      } else {
        navigate('/admin/login', { replace: true })
      }
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/alert`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
          }
        })
        console.log(res)
        setTableData(res.data)
      } catch (error) {
        console.error(error)
      }
    }
    getData()
  }, [])


  const columns = [
    {
      title: 'Kuvaus',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Linkki',
      dataIndex: 'link_id',
      key: 'link_id',
      render: text => <Text strong><Paragraph copyable>{window.location.origin + '/' + text}</Paragraph></Text>,
    },
    {
      title: 'Triggeri koodit',
      key: 'trigger',
      render: (text, record) => (
        <>
          <Text>Aktivointi: <Text strong><Paragraph copyable>{record.trigger_id}</Paragraph></Text></Text>
          <Text>Peruutus: <Text strong><Paragraph copyable>{'cancel_' + record.trigger_id}</Paragraph></Text></Text>
        </>
      ),
    },
    {
      title: 'Voimassa',
      key: 'valid',
      render: (text, record) => (
        <p>{record.valid_from.substring(0, 10)} - {record.valid_to.substring(0, 10)}</p>
      ),
    },
    {
      title: 'Poista',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button danger onClick={() => showDeleteConfirm(record.id)}>Poista</Button>
        </Space>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function showDeleteConfirm(id) {
    confirm({
      title: 'Haluatko varmasti poistaa tämän linkin?',
      icon: <ExclamationCircleOutlined />,
      content: 'Tätä toimenpidettä ei pysty perumaan',
      okText: 'Kyllä',
      okType: 'danger',
      cancelText: 'Ei',
      async onOk() {
        console.log('OK', id);
        try {
          const res = await axios.delete(`${process.env.REACT_APP_API_URL}/alert/${id}`, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": apiToken,
            }
          })
          console.log(res)
          const resget = await axios.get(`${process.env.REACT_APP_API_URL}/alert`, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token'),
            }
          })
          console.log(resget)
          setTableData(resget.data)
        } catch (error) {
          console.error(error)
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onFinish = async (values) => {
    form.resetFields();
    console.log(values.valid[0].toISOString())
    console.log('Success:', values);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token'),
        }
      }
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/alert`, values, config)
      console.log(res)
      
      const resget = await axios.get(`${process.env.REACT_APP_API_URL}/alert`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          }
        })
        console.log(resget)
        setTableData(resget.data)
    } catch (error) {
      console.error(error)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLogout = () => {
    localStorage.clear()
    navigate('/admin/login', { replace: true })
  };

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Header style={{ position: "sticky", top: "0" }}>
      <div className="logo">
        <img src={image} alt=""/>
        <Button style={{float: "right", "margin-top": '15px'}} onClick={handleLogout}>Kirjaudu ulos</Button>
      </div>
      </Header>
      <Content style={{ padding: '0 20px' }}>
        <Button type="primary" onClick={showModal} style={{ "margin-bottom": '5px', "margin-top": '10px' }}>Uusi</Button>
        <Table columns={columns} dataSource={tableData} />
        <Modal title="Uusi"
          mask={false}
          visible={isModalVisible}
          onOk={handleOk}
          width={500}
          footer={[
            <Button key="close_btn" onClick={handleOk}>
              Sulje
            </Button>,
          ]}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            disabled={formDisabled}
          >
            <Form.Item
              label="Kuvaus"
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Voimassa"
              name="valid"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker placeholder={['Alkaa', 'Päättyy']} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Tallenna
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
      <Footer style={{ position: "sticky", bottom: "0", textAlign: 'center' }}>Powered by Idearuutu.fi</Footer>
    </Layout>
  );
}

function Alert() {
  let params = useParams()
  let navigate = useNavigate()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  useEffect(() => {
    // console.log(params)
    const check = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/check_link`, { link_id: params.alertId }, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        console.log(res)
        if (res.data.valid === false) {
          console.error('not valid')
        } else {
          setButtonDisabled(false)
        }
      } catch (error) {
        console.error(error)
        navigate('/', { replace: true })
      }
    }
    check()
  }, [])


  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const cancel_alert = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/cancel_alert`, { link_id: params.alertId }, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      console.log(res)
      openNotificationWithIcon('success', 'Hälytys peruttu')
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', 'Virhe!')
    }
  }

  function activeAlertConfirm() {
    confirm({
      title: 'Aktivoi hälytys',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Kyllä',
      okType: 'danger',
      cancelText: 'Ei',
      async onOk() {
        try {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/active_alert`, {link_id: params.alertId }, {
            headers: {
              "Content-Type": "application/json",
            }
          })
          console.log(res)
          openNotificationWithIcon('success', 'Hälytys aktivoitu')
        } catch (error) {
          console.error(error)
          openNotificationWithIcon('error', 'Virhe!')
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Header style={{ position: "sticky", top: "0" }}>
      <div className="logo">
        <img src={image} alt=""/>
      </div>
      </Header>
      <Content style={{ padding: '0 20px' }}>
        <Row type="flex" justify="center" align="middle" >
          <Col>
            <Card style={{ width: 300, "margin-top": '35vh' }}>
              <Button block size='large' onClick={activeAlertConfirm} type="primary" danger style={{ "margin-bottom": '20px' }} disabled={buttonDisabled}>Hälytys</Button>
              <br />
              <Button block size='large' onClick={cancel_alert} disabled={buttonDisabled}>Peruutus</Button>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer style={{ position: "sticky", bottom: "0", textAlign: 'center' }}>Powered by Idearuutu.fi</Footer>
    </Layout>
  );
}

function NotFound() {
  return (
    <>
      <p>
        Ei löydy, tarkista linkki
      </p>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/:alertId" element={<Alert />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App
