import { Layout, Card, Row, Button, Col, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import axios from "axios"
import { useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;

let config = {
  headers: {
    "Content-Type": "application/json",
  }
}

const Login = () => {
  let navigate = useNavigate()

  const onFinish = async (values) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/login`, values, config)
      console.log(res)
      localStorage.setItem('token', res.data.token)
      navigate('/admin', { replace: true })
    } catch (error) {
      console.error(error)
    }

  };

  return (
    <Layout className="layout">
      <Content style={{ padding: '0 20px' }}>
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col>
            <Card style={{ width: 300 }}>
              <Form
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button block type="primary" htmlType="submit" className="login-form-button">
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;